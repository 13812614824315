import React, { useState, useEffect, useRef} from 'react';
import { Gender } from './Forms/Gender';
import { Age } from './Forms/Age';
import { Goal } from './Forms/Goal';
import Register from './Forms/Register';
import Intro from './Intro';
import Height from './Forms/Height';
import Weight from './Forms/Weight';
import Fat from './Forms/Fat';
import Target from './Forms/Target';
import Level from './Forms/Level';
import Active from './Forms/Active';
import Place from './Forms/Place';
import Nutrition from './Forms/Nutrition';
import Frequency from './Forms/Frequency';
import BMI from './Forms/BMI';
import Buynow from './Forms/Buynow';
import Generating from'./Forms/Generating';
import Pay from'./Forms/Pay';
import { useNavigate } from 'react-router-dom';
import Info1 from './Forms/Info1';
import Info2 from './Forms/Info2';
import { getAuth, createUserWithEmailAndPassword, deleteUser } from "firebase/auth";

export function Questions (authenticated){


    const [page, setPage] = useState(0);
    
    const [formData, setFormData] = useState({
        gender: "",
        age: "",
        goal: "",
        weight: "",
        height: "",
        bodyfat: "",
        problemArea: "",
        fitnessLevel: "",
        dailyActivity: "",
        workoutType: "",
        eatingHabits: "",
        workoutsNumber: "",
        email: "",
        password: "",
        confirmpassword: "",
        firstName: "",
        lastName: "",
        id: ""
    });

    const [questionAnswered, setQuestionAnswered] = useState(0);

    const [error, setError] = useState(null);

    const handleSetGender = (selectedGender) => {
        setFormData((prevFormData) => ({
          ...prevFormData,
          gender: selectedGender,
        }));
    };

    const handleSetGoal = (selectedGoal) => {
        setFormData((prevFormData) => ({
          ...prevFormData,
          goal: selectedGoal,
        }));
    };

    const handleSetFat = (selectedFat) => {
        setFormData((prevFormData) => ({
          ...prevFormData,
          bodyfat: selectedFat,
        }));
    };

    const handleSetTarget = (selectedTarget) => {
        setFormData((prevFormData) => ({
          ...prevFormData,
          problemArea: selectedTarget,
        }));
    };

    const handleSetActive = (selectedActive) => {
        setFormData((prevFormData) => ({
          ...prevFormData,
          dailyActivity: selectedActive,
        }));
    };

    const handleSetPlace = (selectedPlace) => {
        setFormData((prevFormData) => ({
          ...prevFormData,
          workoutType: selectedPlace,
        }));
    };

    const handleSetNutrition = (selectedNutrition) => {
        setFormData((prevFormData) => ({
          ...prevFormData,
          eatingHabits: selectedNutrition,
        }));
    };

    const handleSetFrequency = (selectedFrequency) => {
        setFormData((prevFormData) => ({
          ...prevFormData,
          workoutsNumber: selectedFrequency,
        }));
    };

    function availableEmail(){

        const atSymbolIndex = formData.email.indexOf("@");
        const availableEmailBody = {email: formData.email};
        console.log(availableEmailBody);

        if (atSymbolIndex > 0 && atSymbolIndex < formData.email.length - 1) {

            //alert("email is fine");

            fetch('https://server.fitbuddy.co.il/api/users/verifyAvailableMail', {
                headers: {
                    'Content-Type': 'application/json'
                    },
                method: 'POST',
                mode: 'cors',
                body: JSON.stringify(availableEmailBody)
            })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                if (response.status == 200){
                    alert("המייל כבר קיים במערכת");
                }
                else if (response.status == 201){
                    setPage(page + 1);
                }
                else {
                    return;
                }
            })
            .then(data => 
            {

            })
            .catch((error) => 
            {
                console.log("Failed:");
                console.log(error);
            });

        } else {
            alert("כתובת המייל לא תקינה");
        }
    }


    function submited() 
    {
        console.log("clicked on register");

        if (formData.password.length < 8) {
            alert("הסיסמה צריכה להיות בעלת יותר מ-8 תווים")
        }
        else{ 
            console.log("clicked on register");
            if(formData.password !== formData.confirmpassword){
                alert("הסיסמה לא תואמת");
            }
            else{
                const auth = getAuth();
                createUserWithEmailAndPassword(auth, formData.email, formData.password)
                .then((userCredential) => 
                    {
                    // Signed up 
                    const user = userCredential.user;
                    formData.userId = user.uid;
                    // ...
                    fetch('https://server.fitbuddy.co.il/api/users/register', {
                        headers: {
                            'Content-Type': 'application/json'
                            },
                        method: 'POST',
                        mode: 'cors',
                        body: JSON.stringify(formData)
                    })
                    .then(data => 
                    {
                        if (!data.ok) {
                            alert("ההרשמה נכשלה!");
                            console.log("registeration failed at internal register");
                            deleteUser(auth.currentUser);
                        }
                        console.log('Register successful:', data);
                        console.log(JSON.stringify(formData));
                        let name = formData.firstName;
                        //window.location.href = 'https://fitbuddy.co.il/download-app?john';
                        window.location.href = `https://fitbuddy.co.il/download-app?${name}`;
                    })
                    .catch((error) => 
                    {
                        const errorCode = error.code;
                        const errorMessage = error.message;
                        alert("ההרשמה נכשלה!");
                        console.log("registeration failed at internal register with error:", errorMessage, errorCode);
                        deleteUser(auth.currentUser);
                    });
                })
                .catch((error) => {
                    const errorCode = error.code;
                    const errorMessage = error.message;
                    alert("ההרשמה נכשלה!");
                    console.log("registeration failed at firebase with error:", errorMessage, errorCode);
                });
            window.postMessage('navigateToOtherPage', '*');
            console.log(formData);
            }
        }
    }

    const FormTitles = [
    "מה המין שלך?"
    ,"מה הגיל שלך?"
    ,"מה המטרה שלך?"
    ,"מה המשקל שלך? (בק\"ג)"
    ,""
    ,"מה הגובה שלך? (בס\"מ)"
    ,"מה אחוזי השומן שלך?"
    ,"במה היית רוצה להתמקד?"
    ,"מהי רמת הכושר שלך?"
    ,"מהי רמת הפעילות היומיומית שלך?"
    ,""
    ,"מה סגנון האימונים המועדף עליך?"
    ,"מה מהבאים מתאר את הרגלי האכילה שלך?"
    ,"כמה אימונים בשבוע תרצה/י לעשות?"
    ,"הכנס את הדוא\"ל שלך וקבל תוכנית אימונים ותפריט תזונה"
    ,""
    ,"הנתונים שלך:"
    ,"הרשמה"
    ]

    const progressWidth = (page / (FormTitles.length-1))*100;


    const PageDisplay = () => {

        if(page === 0){
            return <Gender genderRadio={formData.gender} setGenderRadio={handleSetGender} page={page} setPage={setPage}/>;
        }
        if(page === 1){
            return <Age formData={formData} setFormData={setFormData} page={page} setPage={setPage} questionAnswered={questionAnswered} setQuestionAnswered={setQuestionAnswered}/>;
        }
        if(page === 2){
            return <Goal formData={formData} goalRadio={formData.goal} setGoalRadio={handleSetGoal} page={page} setPage={setPage}/>;
        }
        if(page === 3){
            return <Weight formData={formData} setFormData={setFormData} page={page} setPage={setPage} questionAnswered={questionAnswered} setQuestionAnswered={setQuestionAnswered}/>;
        }
        if(page === 4){
            return <Info1 formData={formData} setFormData={setFormData} page={page} setPage={setPage} questionAnswered={questionAnswered} setQuestionAnswered={setQuestionAnswered}/>;
        }
        if(page === 5){
            return <Height formData={formData} setFormData={setFormData} page={page} setPage={setPage} questionAnswered={questionAnswered} setQuestionAnswered={setQuestionAnswered}/>;
        }
        if(page === 6){
            return <Fat formData={formData} setFormData={setFormData} questionAnswered={questionAnswered} setQuestionAnswered={setQuestionAnswered} page={page} setPage={setPage}/>;
        }
        if(page === 7){
            return <Target formData={formData} setFormData={setFormData} targetRadio={formData.target} setTargetRadio={handleSetTarget} page={page} setPage={setPage}/>;
        }
        if(page === 8){
            return <Level formData={formData} setFormData={setFormData} questionAnswered={questionAnswered} setQuestionAnswered={setQuestionAnswered}/>;
        }
        if(page === 9){
            return <Active activeRadio={formData.active} setActiveRadio={handleSetActive} page={page} setPage={setPage}/>;
        }
        if(page === 10){
            return <Info2 formData={formData} setFormData={setFormData} page={page} setPage={setPage} questionAnswered={questionAnswered} setQuestionAnswered={setQuestionAnswered}/>;
        }
        if(page === 11){
            return <Place placeRadio={formData.place} setPlaceRadio={handleSetPlace} page={page} setPage={setPage}/>;
        }
        if(page === 12){
            return <Nutrition nutritionRadio={formData.nutrition} setNutritionRadio={handleSetNutrition} page={page} setPage={setPage}/>;
        }
        if(page === 13){
            return <Frequency frequencyRadio={formData.frequency} setFrequencyRadio={handleSetFrequency} page={page} setPage={setPage}/>;
        }
        if(page === 14){
            return <Register formData={formData} setFormData={setFormData} page={page} setPage={setPage} questionAnswered={questionAnswered} setQuestionAnswered={setQuestionAnswered} availableEmail={availableEmail}/>;
        }
        if(page === 15){
            return <Generating page={page} setPage={setPage}/>;
        }
        if(page === 16){
            return <Buynow formData={formData} setFormData={setFormData} questionAnswered={questionAnswered} setQuestionAnswered={setQuestionAnswered} page={page} setPage={setPage}/>;
        }
        if(page === 17){
            return <Pay formData={formData} setFormData={setFormData} questionAnswered={questionAnswered} setQuestionAnswered={setQuestionAnswered} submited={submited}/>;
        }

    };


    const pageRef = useRef(page);

    useEffect(() => {
        // Update the ref with the latest page value
        pageRef.current = page;
    }, [page]);
    
    useEffect(() => {
        // Add a dummy entry to the history stack
        window.history.pushState(null, null, window.location.pathname);
    
        const handlePopState = (event) => {
            // Push the dummy entry again to the history stack immediately
            window.history.pushState(null, null, window.location.pathname);
            // Custom action on back button press
            setPage(pageRef.current - 1);
        };
    
        // Listen for the popstate event
        window.addEventListener('popstate', handlePopState);
    
        return () => {
            // Cleanup the event listener on component unmount
            window.removeEventListener('popstate', handlePopState);
        };
    }, []);



    return(
        <div className="form">
            <div className="progressbar">
                <div style={{width: progressWidth + "%"}}></div>
            </div>
            <div className="form-container">
                <div className="form-header">
                    <h1>{FormTitles[page]}</h1>
                </div>
                <div className="form-body">
                    {PageDisplay()}
                    <p className="error">{error}</p>
                </div>

                {page < 15 && (
                <div className="form-footer"
                style={page === FormTitles.length -2 ? { bottom:0,backgroundColor:"#191923",right:0,left:0,padding:10,paddingRight:32,paddingLeft:32,borderTop:"1px solid #2B2B3C"} :
                page === FormTitles.length -1 ? {bottom:20} :
                {}}>

                    <button className="button-blue" hidden={questionAnswered === 0} onClick={() => {
                        if (page == FormTitles.length -1){
                            submited();
                        }
                        else if (page == 14){
                            availableEmail();
                        }
                        else{
                            setTimeout(() => {
                                setPage(page + 1);
                                setQuestionAnswered(0);
                            }, 250);
                        }
                        }}>

                        {page === 4 || page === 10 ? "הבנתי, בואו נמשיך!" :
                        page === FormTitles.length -2 ? "אני רוצה לקבל את התוכנית ואת תפריט התזונה שלי בחינם" :
                        page === FormTitles.length -1 ? "הרשמה" :
                        "הבא"}
                        </button>

                    <button style={{marginTop:10,backgroundColor:"#2B2B3C"}} hidden={page === 0} className="button-white-border"
                    onClick={() => {setPage(page -1)}}>הקודם</button>

                </div>
                )}

            </div>
        </div>
    )
}

export default Questions;